define("ember-ajax-fetch/services/fetch", ["exports", "ember-ajax-fetch/mixins/fetch-request"], function (_exports, _fetchRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FetchService
   */
  const FetchService = Ember.Service.extend(_fetchRequest.default);
  var _default = FetchService;
  _exports.default = _default;
});