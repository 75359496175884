define("ember-metrics/metrics-adapters/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function makeToString(ret) {
    return () => ret;
  }

  class BaseAdapter extends Ember.Object {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "metrics", null);

      _defineProperty(this, "config", null);
    }

    init() {
      (false && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the init hook!`));
    }

    willDestroy() {
      (false && !(false) && Ember.assert(`[ember-metrics] ${this.toString()} must implement the willDestroy hook!`));
    }

    toString() {
      const hasToStringExtension = Ember.typeOf(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${Ember.guidFor(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }

    identify() {}

    trackEvent() {}

    trackPage() {}

    alias() {}

  }

  _exports.default = BaseAdapter;

  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});