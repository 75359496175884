define("ember-ajax-fetch/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFetchError = isFetchError;
  _exports.isTimeoutError = isTimeoutError;
  _exports.ServerError = _exports.ConflictError = _exports.AbortError = _exports.TimeoutError = _exports.GoneError = _exports.NotFoundError = _exports.BadRequestError = _exports.ForbiddenError = _exports.UnauthorizedError = _exports.InvalidError = _exports.FetchError = void 0;

  class FetchError extends Error {
    constructor(payload, message = 'Ajax operation failed', status) {
      super(message);
      this.payload = payload;
      this.status = status;
    }

  }

  _exports.FetchError = FetchError;

  class InvalidError extends FetchError {
    constructor(payload) {
      super(payload, 'Request was rejected because it was invalid', 422);
    }

  }

  _exports.InvalidError = InvalidError;

  class UnauthorizedError extends FetchError {
    constructor(payload) {
      super(payload, 'Ajax authorization failed', 401);
    }

  }

  _exports.UnauthorizedError = UnauthorizedError;

  class ForbiddenError extends FetchError {
    constructor(payload) {
      super(payload, 'Request was rejected because user is not permitted to perform this operation.', 403);
    }

  }

  _exports.ForbiddenError = ForbiddenError;

  class BadRequestError extends FetchError {
    constructor(payload) {
      super(payload, 'Request was formatted incorrectly.', 400);
    }

  }

  _exports.BadRequestError = BadRequestError;

  class NotFoundError extends FetchError {
    constructor(payload) {
      super(payload, 'Resource was not found.', 404);
    }

  }

  _exports.NotFoundError = NotFoundError;

  class GoneError extends FetchError {
    constructor(payload) {
      super(payload, 'Resource is no longer available.', 410);
    }

  }

  _exports.GoneError = GoneError;

  class TimeoutError extends FetchError {
    constructor() {
      super(null, 'The ajax operation timed out', -1);
    }

  }

  _exports.TimeoutError = TimeoutError;

  class AbortError extends FetchError {
    constructor() {
      super(null, 'The ajax operation was aborted', 0);
      this.name = 'AbortError';
    }

  }

  _exports.AbortError = AbortError;

  class ConflictError extends FetchError {
    constructor(payload) {
      super(payload, 'The ajax operation failed due to a conflict', 409);
    }

  }

  _exports.ConflictError = ConflictError;

  class ServerError extends FetchError {
    constructor(payload, status) {
      super(payload, 'Request was rejected due to server error', status);
    }

  }
  /**
   * Checks if the given error is or inherits from FetchError
   * @function isFetchError
   */


  _exports.ServerError = ServerError;

  function isFetchError(error) {
    return error instanceof FetchError;
  }
  /**
   * Checks if the given object represents a "timeout" error
   * @function isTimeoutError
   */


  function isTimeoutError(error) {
    return error instanceof TimeoutError;
  }
});