define("ember-power-datepicker/components/power-datepicker", ["exports", "@ember-decorators/component", "ember-power-datepicker/templates/components/power-datepicker", "ember-power-datepicker/utils/computed-properties"], function (_exports, _component, _powerDatepicker, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _class3, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = (0, _component.layout)(_powerDatepicker.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _computedProperties.fallbackAction)(function (day) {
    this.set('center', day.date);
  }), _dec4 = Ember._action, _dec(_class2 = _dec2(_class2 = (_class3 = class _class3 extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "closeOnSelect", true);

      _defineProperty(this, "format", 'YYYY/MM/DD');

      _initializerDefineProperty(this, "onCenterChange", _descriptor, this);
    }

    // Actions
    handleSelect(day, datepicker, e) {
      let {
        onSelect,
        closeOnSelect
      } = this.getProperties('onSelect', 'closeOnSelect');
      let value = onSelect(day, datepicker, e);

      if (value === false || !closeOnSelect) {
        return;
      }

      datepicker.actions.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "onCenterChange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "handleSelect", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSelect"), _class3.prototype)), _class3)) || _class2) || _class2);

  _exports.default = _class;
});