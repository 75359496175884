define("ember-awesome-macros/array/-utils", ["exports", "ember-macro-helpers/normalize-array-key", "ember-macro-helpers"], function (_exports, _normalizeArrayKey, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeArray = normalizeArray;
  _exports.normalizeArray2 = normalizeArray2;
  _exports.normalizeArray3 = normalizeArray3;
  const sentinelValue = {};

  function normalizeArrayArgs(keys) {
    keys[0] = (0, _normalizeArrayKey.default)(keys[0]);
  }

  function convertArray(array) {
    if (array instanceof Ember.ArrayProxy) {
      return array;
    }

    if (Array.isArray(array)) {
      // we use .slice() here so that plain arrays are
      // not internally mutated to Ember arrays
      return Ember.A(array.slice());
    }

    if (Ember.isArray(array)) {
      // this is required by the current `concat()` implementation because
      // Ember.Array itself does not define `concat()` so it only works
      // for Ember.Array instances that are backed by a real array
      return Ember.A(array.toArray());
    }

    return null;
  }

  function getDefaultValue(func, identityVal) {
    let val = func();
    return val === sentinelValue ? identityVal : val;
  }

  function normalizeArray({
    defaultValue = () => sentinelValue
  }, callback) {
    return (...keys) => {
      normalizeArrayArgs(keys);
      return (0, _emberMacroHelpers.lazyComputed)(...keys, function (get, arrayKey, ...args) {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        let values = args.map(get);
        return callback.call(this, emberArrayVal, ...values);
      });
    };
  }

  function normalizeArray2(funcStr, defaultValue = () => sentinelValue) {
    return (...keys) => {
      normalizeArrayArgs(keys);
      return (0, _emberMacroHelpers.lazyComputed)(...keys, (get, arrayKey, ...args) => {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        let prop = emberArrayVal[funcStr];

        if (typeof prop === 'function') {
          return prop.apply(emberArrayVal, args.map(get));
        }

        return prop;
      });
    };
  }

  function normalizeArray3({
    firstDefault = () => sentinelValue,
    secondDefault = () => sentinelValue,
    func
  }) {
    return (0, _emberMacroHelpers.createClassComputed)([false, true], (array, key, ...args) => {
      return (0, _emberMacroHelpers.lazyComputed)((0, _normalizeArrayKey.default)(array, [key]), ...args, function (get, arrayKey, ...args) {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(firstDefault, arrayVal);
        }

        if (typeof key !== 'string') {
          return getDefaultValue(secondDefault, arrayVal);
        }

        let resolvedArgs = [key, ...args.map(get)];

        if (typeof func === 'function') {
          return func(emberArrayVal, ...resolvedArgs);
        }

        return emberArrayVal[func](...resolvedArgs);
      });
    });
  }
});