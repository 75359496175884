define("ember-attacher/templates/components/attach-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XxsZd0ZB",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[1,\"\\n\\n\"],[41,[28,[37,3],[[33,4],[28,[37,5],[[28,[37,6],[[33,7]],null],[33,8]],null]],null],[[[6,[39,9],null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[33,10],\"ember-attacher\",false,[33,11],[33,12],[33,13],[33,14],[33,4],[28,[37,15],[[30,0],\"_registerAPI\"],null],[33,16]]],[[\"default\"],[[[[1,\"    \"],[10,0],[15,0,[29,[[36,17]]]],[15,5,[36,18]],[12],[1,\"\\n      \"],[18,2,[[28,[37,20],null,[[\"emberPopper\",\"hide\"],[[30,1],[28,[37,15],[[30,0],\"hide\"],null]]]]]],[1,\"\\n\\n\"],[41,[33,21],[[[1,\"        \"],[10,0],[14,\"x-arrow\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,22],[[33,23],\"fill\"],null],[[[1,\"        \"],[10,0],[14,\"x-circle\",\"\"],[15,5,[36,24]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1]]]]]],[]],null]],[\"emberPopper\",\"&default\"],false,[\"unbound\",\"_parentFinder\",\"if\",\"and\",\"_currentTarget\",\"or\",\"not\",\"lazyRender\",\"_mustRender\",\"ember-popper\",\"ariaRole\",\"id\",\"_modifiers\",\"placement\",\"popperContainer\",\"action\",\"renderInPlace\",\"_class\",\"_style\",\"yield\",\"hash\",\"arrow\",\"eq\",\"animation\",\"_circleTransitionDuration\"]]",
    "moduleName": "ember-attacher/templates/components/attach-popover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});