define("ember-power-datepicker/utils/computed-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fallbackAction = fallbackAction;

  function fallbackAction(fallback) {
    return Ember.computed({
      get() {
        return fallback.bind(this);
      },

      set(_, v) {
        return v === undefined ? fallback.bind(this) : v;
      }

    });
  }
});