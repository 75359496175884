define("ember-awesome-macros/tag", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(strings, ...values) {
    return (0, _emberMacroHelpers.curriedComputed)((...values) => {
      return strings.reduce((total, cur, i) => {
        return total + values[i - 1] + cur;
      });
    })(...values);
  }
});