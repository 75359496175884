define("ember-metrics/services/metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    keys
  } = Object;

  class Metrics extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "_adapters", {});

      _defineProperty(this, "context", {});

      _defineProperty(this, "enabled", true);
    }

    /**
     * When the Service is created, activate adapters that were specified in the
     * configuration. This config is injected into the Service as
     * `options`.
     *
     * @method init
     * @param {Void}
     * @return {Void}
     */
    init() {
      const adapters = this.options.metricsAdapters || Ember.A();
      const owner = Ember.getOwner(this);
      owner.registerOptionsForType('ember-metrics@metrics-adapter', {
        instantiate: false
      });
      owner.registerOptionsForType('metrics-adapter', {
        instantiate: false
      });
      Ember.set(this, 'appEnvironment', this.options.environment || 'development');
      this.activateAdapters(adapters);
      super.init(...arguments);
    }

    identify(...args) {
      this.invoke('identify', ...args);
    }

    alias(...args) {
      this.invoke('alias', ...args);
    }

    trackEvent(...args) {
      this.invoke('trackEvent', ...args);
    }

    trackPage(...args) {
      this.invoke('trackPage', ...args);
    }
    /**
     * Instantiates the adapters specified in the configuration and caches them
     * for future retrieval.
     *
     * @method activateAdapters
     * @param {Array} adapterOptions
     * @return {Object} instantiated adapters
     */


    activateAdapters(adapterOptions = []) {
      const appEnvironment = this.appEnvironment;
      const cachedAdapters = this._adapters;
      const activatedAdapters = {};
      adapterOptions.filter(adapterOption => this._filterEnvironments(adapterOption, appEnvironment)).forEach(adapterOption => {
        const {
          name,
          config
        } = adapterOption;

        const adapterClass = this._lookupAdapter(name);

        if (typeof FastBoot === 'undefined' || adapterClass.supportsFastBoot) {
          const adapter = cachedAdapters[name] || this._activateAdapter({
            adapterClass,
            config
          });

          Ember.set(activatedAdapters, name, adapter);
        }
      });
      return Ember.set(this, '_adapters', activatedAdapters);
    }
    /**
     * Invokes a method on the passed adapter, or across all activated adapters if not passed.
     *
     * @method invoke
     * @param {String} methodName
     * @param {Rest} args
     * @return {Void}
     */


    invoke(methodName, ...args) {
      if (!this.enabled) {
        return;
      }

      const cachedAdapters = this._adapters;
      const allAdapterNames = keys(cachedAdapters);
      const [selectedAdapterNames, options] = args.length > 1 ? [Ember.makeArray(args[0]), args[1]] : [allAdapterNames, args[0]];
      const context = Ember.assign({}, this.context);
      const mergedOptions = Ember.assign(context, options);
      selectedAdapterNames.map(adapterName => cachedAdapters[adapterName]).forEach(adapter => adapter && adapter[methodName](mergedOptions));
    }
    /**
     * On teardown, destroy cached adapters together with the Service.
     *
     * @method willDestroy
     * @param {Void}
     * @return {Void}
     */


    willDestroy() {
      const cachedAdapters = this._adapters;

      for (let adapterName in cachedAdapters) {
        cachedAdapters[adapterName].destroy();
      }
    }
    /**
     * Instantiates an adapter.
     *
     * @method _activateAdapter
     * @param {Object}
     * @private
     * @return {Adapter}
     */


    _activateAdapter({
      adapterClass,
      config
    }) {
      return adapterClass.create(Ember.getOwner(this).ownerInjection(), {
        this: this,
        config
      });
    }
    /**
     * Looks up the adapter from the container. Prioritizes the consuming app's
     * adapters over the addon's adapters.
     *
     * @method _lookupAdapter
     * @param {String} adapterName
     * @private
     * @return {Adapter} a local adapter or an adapter from the addon
     */


    _lookupAdapter(adapterName) {
      (false && !(adapterName) && Ember.assert('[ember-metrics] Could not find metrics adapter without a name.', adapterName));
      const dasherizedAdapterName = Ember.String.dasherize(adapterName);
      const availableAdapter = Ember.getOwner(this).lookup(`ember-metrics@metrics-adapter:${dasherizedAdapterName}`);
      const localAdapter = Ember.getOwner(this).lookup(`metrics-adapter:${dasherizedAdapterName}`);
      const adapter = localAdapter || availableAdapter;
      (false && !(adapter) && Ember.assert(`[ember-metrics] Could not find metrics adapter ${adapterName}.`, adapter));
      return adapter;
    }
    /**
     * Predicate that Filters out adapters that should not be activated in the
     * current application environment. Defaults to all environments if the option
     * is `all` or undefined.
     *
     * @method _filterEnvironments
     * @param {Object} adapterOption
     * @param {String} appEnvironment
     * @private
     * @return {Boolean} should an adapter be activated
     */


    _filterEnvironments(adapterOption, appEnvironment) {
      let {
        environments
      } = adapterOption;
      environments = environments || ['all'];
      const wrappedEnvironments = Ember.A(environments);
      return wrappedEnvironments.indexOf('all') > -1 || wrappedEnvironments.indexOf(appEnvironment) > -1;
    }

  }

  _exports.default = Metrics;
});