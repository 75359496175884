define("ember-power-select/components/power-select/trigger", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" {{on "mousedown" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
  
  */
  {
    "id": "QDEVWq4x",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[30,2],null,[[\"extra\",\"option\",\"select\"],[[28,[37,2],[[30,3]],null],[28,[37,2],[[30,1,[\"selected\"]]],null],[28,[37,2],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[18,7,[[30,1,[\"selected\"]],[30,1]]],[13],[1,\"\\n\"]],[]]],[41,[28,[37,4],[[30,4],[28,[37,5],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"    \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[4,[38,6],[\"mousedown\",[30,0,[\"clear\"]]],null],[4,[38,6],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[46,[30,5],null,[[\"placeholder\"],[[30,6]]],null],[1,\"\\n\"]],[]]],[10,1],[14,0,\"ember-power-select-status-icon\"],[12],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@allowClear\",\"@placeholderComponent\",\"@placeholder\",\"&default\"],false,[\"if\",\"component\",\"readonly\",\"yield\",\"and\",\"not\",\"on\"]]",
    "moduleName": "ember-power-select/components/power-select/trigger.hbs",
    "isStrictMode": false
  });

  let Trigger = (_dec = Ember._action, (_class = class Trigger extends _component.default {
    clear(e) {
      e.stopPropagation();
      this.args.select.actions.select(null);

      if (e.type === 'touchstart') {
        return false;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clear", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class));
  _exports.default = Trigger;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Trigger);
});