define("ember-awesome-macros/promise/hash", ["exports", "ember-macro-helpers", "ember-awesome-macros/hash"], function (_exports, _emberMacroHelpers, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(...args) {
    let {
      hashKeys,
      hashValues
    } = (0, _hash.deconstructArgs)(args);
    return (0, _emberMacroHelpers.curriedComputed)((...newValues) => {
      let newHash = (0, _hash.reduceValues)(hashKeys, newValues);
      return Ember.RSVP.hash(newHash);
    })(...hashValues);
  }
});